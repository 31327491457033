import React, { useState, useRef, useEffect  }  from 'react';
import ArticleTeaser from '../articles/ArticleTeaser';
import "./portfolio.scss"

const Portfolio = ({ setVisibiliti }) => {

    const [articlesList, setArticlesList] = useState([
        { id: 3,
            title: "Secure Access System Application",
            content: [

                '<p className="articleteaser--p articleteaser--topspace">Jest to w zasadzie połączenie moich poprzednich projektów, uzupełnione o uprawnienia w miesce ról użytkowników.</p>',
                '<p className="articleteaser--p articleteaser--topspace">Czerpie dużo z projektu opisanego w "My Authorisation System - test", oraz na bazie "I want to be logged in to the internship" powstało moje nowe rozwiązanie będące odpowiedzią na potrzebę rejestracji i resetowania hasła. Stanowi kanwę do możliwości rozbudowy o różne uprawnienia w formie listy.</p><br><br>',
                '<p className="articleteaser--p articleteaser--topspace">Dane logowania do swaggera:<br>&emsp;&emsp;User: no-reply@major.ovh<br>&emsp;&emsp;Hasło: Ex@mple2</p><br>',
                '<p className="articleteaser--p">Środowisko: InteliJ IDEA Ultimate</p>',
                '<p className="articleteaser--p">Wykorzystałem: Java 21, Maven, Spring Boot 3.2.4, JWT, Lombok, Docker, Flyway, Java Mail, Postgres</p>',
                '<p className="articleteaser--p">Starałem się stosować SOLID.</p>',
                '<h2 className="articleteaser--h2">Do przeklikania:</h2>',
                '<div className="articleteaser--flex"><a className="articleteaser--a articleteaser--topspace" href="https://github.com/grzegorzmajor/SecureAccessSystem" target="_blank" rel="noreferrer">Link do GitHuba.</a><a className="articleteaser--a articleteaser--topspace" href="https://api.major.ovh:26690/swagger-ui/index.html" target="_blank" rel="noreferrer">Swagger.</a></div>'

                ],
            isMaximized: false },
        { id: 4,
            title: "My Authorisation System - test",
            content: [

                '<p className="articleteaser--p articleteaser--topspace">To API to mój mały, prosty projekt do testowania rozwiązań autoryzacyjnych. To tylko 1 wersja, na której testowałem rozwiązanie dla Spring Boot 3.2.x.</p>',
                '<p className="articleteaser--p articleteaser--topspace">Zakładane zastosowanie kilku łańcuchów filtrów zapewniających wiele poziomów autoryzacji (otwarte punkty końcowe, baza Swagger, tokeny dla punktów końcowych wymagających autoryzacji), różne role użytkowników, podwójne tokeny - RefreshingToken, AccessToken. Pierwszy RefreshingToken, długoterminowy (tutaj 120 minut) - służy wyłącznie do odnowienia dostępu. Drugi AccessToken, krótkoterminowy (5 minut) - służy do autoryzacji dostępu do API (tutaj tylko trzy testowe punkty końcowe). Jeśli chcesz przetestować - poniżej jest link do Swaggera.<br>Dane do logowania: user:swaggerUser hasło:pass<br>Dane do logowania do api - w opisie na stronie swaggera</p><br>',

                '<p className="articleteaser--p">Środowisko: InteliJ IDEA Ultimate</p>',
                '<p className="articleteaser--p">Wykorzystałem: Java 17, Maven, Spring Boot 3, JWT, Lombok, Docker</p>',
                '<p className="articleteaser--p">Starałem się stosować SOLID.</p>',
                '<h2 className="articleteaser--h2">Do przeklikania:</h2>',
                '<div className="articleteaser--flex"><a className="articleteaser--a articleteaser--topspace" href="https://github.com/grzegorzmajor/MyAuthorisationSystemTest" target="_blank" rel="noreferrer">Link do GitHuba.</a><a className="articleteaser--a articleteaser--topspace" href="https://api.major.ovh:28889/swagger-ui/index.html" target="_blank" rel="noreferrer">Swagger.</a></div>'

                ],
            isMaximized: false },
        { id: 5,
            title: "I want to be logged in to the internship",
            content: [

                '<p className="articleteaser--p articleteaser--topspace">Mój projekt rekrutacyjny na staż. Rejestracja i logowanie użytkowników wraz z wysyłaniem e-maili z linkiem potwierdzającym + usuwanie i update. Uwierzytelnianie JWT. Moje pierwsze podejście do mailingu w aplikacji. Testy integracyjne na testowych kontenerach. Integracja z serwerem poczty Gmail.</p>',
                '<p className="articleteaser--p articleteaser--topspace">Ciekawostką jest fakt, że bardziej rozbudowana wersja tego projektu została przeze mnie wdrożona już w pierwszym tygodniu tego stażu. Wersja wdrożeniowa tego projektu uwzględniała tworzenie kona użytkownika wraz z pełnymi danymi, dopiero po potwierdzeniu linkiem z e-maila. Przy użyciu tego samego systemu (adaptując go) również reset hasała, przydzielenie losowego pinu.</p><br>',

                '<p className="articleteaser--p">Środowisko: InteliJ IDEA Ultimate</p>',
                '<p className="articleteaser--p">Wykorzystałem: Clean Code, modular monolith, Java 17, Maven, Spring Boot 3, Spring (Security, Email, Thymeleaf, JPA), JWT, Postgresql, Jakarta Validation, Lombok, Test Containers (PostgreSQL, Mailhog), JUNIT, REST ASSURED, Docker</p>',
                '<p className="articleteaser--p">Starałem się stosować SOLID.</p>',
                '<h2 className="articleteaser--h2">Do przeklikania:</h2>',
                '<div className="articleteaser--flex"><a className="articleteaser--a articleteaser--topspace" href="https://github.com/grzegorzmajor/i_want_to_be_logged_in_to_the_internship" target="_blank" rel="noreferrer">Link do GitHuba.</a><a className="articleteaser--a articleteaser--topspace" href="https://api.major.ovh:28888/swagger-ui/index.html" target="_blank" rel="noreferrer">Swagger.</a></div>'

                ],
            isMaximized: false },
        { id: 6,
            title: "My Backend App",
            content: [
                '<p className="articleteaser--p articleteaser--topspace">Pierwszym zaprojektowanym elementem był system autoryzacji w sposób, by nie był on przerostem formy nad treścią - apka udostępniająca część funkcjonalności tylko jednemu użytkownikowi.</p>',
                '<img className="articleteaser--img articleteaser--img--right" src="/img/entities.png" alt=""/>',
                '<p className="articleteaser--p articleteaser--topspace">Z założenia ma to być apka, która udostępnia podstawowe endpointy odczytu z bazy danych bez logowania. Po zalogowaniu dostepne są endponty edycji i dodawania elementów do bazy danych.<br>W związku z tym dane użytkownika zaczytywane są ze zmiennych środowiskowcych. Nie ma potrzeby tworzyć tabeli w bazie dla jednego użytkownika.</p>',

                '<img className="articleteaser--img articleteaser--img--left" src="/img/i1.png" alt=""/>',

                '<p className="articleteaser--p articleteaser--topspace">Kolejnym etapem było stworzenie modułu obsługującego planowanego bloga. Na początek złożonego z trzech encji połączonych ze sobą odpowiednimi zależnościami i tu z pomocą przyszła mi baza danych na MariaDB postawiona na dockerowym kontenerze i Spring Data JPA.</p>',

                '<p className="articleteaser--p articleteaser--topspace">Po drodze pojawiło się kilka trudności z których najważniejsze to problem z automatyczną konfiguracją dostępu do bazy przez Spring Boota, obsługa zapisu do bazy połączonych zależnościami encji (transakcje oraz unikatowe wartości w jednej z encji).</p>',


                '<p className="articleteaser--p articleteaser--topspace">Zachciało mi ;) się także szyfrowania SSL, które po zaimplemenmtowanmiu rozwalało mi inne funkcjionalności apki (np. Spring Bootową konfigurację dostepu do bazy danych). Ostatecznie okazało się, że moje podejście do SSL jest błędne, bo powinienem to ogarnąć globalnie na cały serwer wraz z implementacją automatycznego odnawiania z letsencrypt i za pomoca nginx skonfigurować proxy który te certyfikaty ogarnie dla każdego z dokerowych kontenerów na serwerze VPS.</p>',
                '<p className="articleteaser--p articleteaser--topspace">Nauka, jaką z tego wszystkiego wyniosłem, to "Naucz się człowieku samego Springa, bo Spring Boot wszystkiego nie załatwi. Gdy Spring Bootowa automatyzacja wszystko zawali z powodzeniem skonfigurujesz wszystko ręcznie!" Dzieki temu własnemu projektowi najlepiej zrozumiałem podstawy Springa.</p>',
                '<p className="articleteaser--p articleteaser--topspace">Pierwsza wersja jednak jest już gotowa i odpalona na moim serwerze na OVH. Nie odbyło się bez trudności, ale ostatecznie udało się wszystko opanować. Dzięki tym problemom sporo się nauczyłem i jestem zadowolony ze swojej pracy - głównie dlatego, że to moje pierwsze podejście do Spring Data JPA, bazy MariaDB, zależności, transakcji.</p>',
                '<p className="articleteaser--p articleteaser--topspace">Korzystając przy okazji z popularnego ostatnio ChataGPT - głównie do nauki i poszukiwania odpowiedzi na nasuwające mi się pytania, wywnioskowałem jedną przypuszczam prawdziwą teorię. Zaznaczam, że jest to tylko moja teoria i nie narzucam tego zdania nikomu, ale uważam że zament na temat utraty pracy przez masy programistów i innych specjalistów z powodu powstania ChataGPT jest tworzony przez ludzi którzy albo nie mają pojęcia jak wygląda specyfika pracy specjalisty w swoim fachu, tak naprawdę mają zerowe doświadczenie z ChatemGPT, złapały chwytliwy temat, albo boją się o swoją własną posadę, bo zmuszenie czata GPT do napisania chwytliwego artukułu niekoniecznie zgodnego z prawdą jest bardzo proste, ale by wykorzystać ten model językowy w pracy konstruktywnie - trzeba wiedzieć co się robi i doskonale zrozumieć zagadnienie o które zadaje się zapytanie, a także umieć precyzyjnie zadawać to pytanie. Model językowy bez względu na to jak bardzo potrafi trzymać się kontekstu, nie siedzi w naszej głowie i nie wie tak naprawdę, że chodzi nam o coś innego niż sami napisaliśmy - chodzi o niuanse w precyzyjnym opisywaniu oczekiwanych efektów.</p>',
                '<p className="articleteaser--p articleteaser--topspace">W planach na przyszłość mam dodanie kategorii postów, ale najpierw chcę postawić na stronie to co mam już zrobione. Czeka mnie sporo trudnej pracy. Strona budowana jest przeze mnie w React, ale nie jest to planowany kierunek mojego rozwoju, dlatego wyzwaniem będzie praca nad tym bez dogłębnej nauki JavaScript i Reacta na bazie wiedzy, której o nich mam. Chociaż ten krok jest do przemyślenia, bo zbyt duże zmiany w projekcie mogą mnie zmusić do przepisania bazy na nowo i szkoda by było generować kontent który będę potem musiał wywalić/przepisać. </p>',
                '<p className="articleteaser--p articleteaser--topspace">Paradoksalnie pomimo, że projekt dotyczył backendu do bloga, poprzez dodanie kategorii wpisów mogę go zaadaptować do generowania również innego kontentu na stronie. Wymaga tylko popracowania nad elatycznością - głównie nad generowaniem tagów html w bardziej elastyczny sposób. Generalnie rzecz biorąc założenie apki jest takie, że zadając zapytanie o kolejny fragment / rekord w bazie mam otrzymać gotowe klocki  do poskładania fragmentu dokumentu html i zapakowania go w komponent Reacta. Można by oczywiście całość składać po stronie backendu do jednego stringa, ale utrudni to potem zaimplementowanie wygodnego sposobu edycji.</p><br>',

                '<p className="articleteaser--p">Środowisko: InteliJ IDEA Ultimate</p>',
                '<p className="articleteaser--p">Wykorzystałem: Maven, Spring, Spring Boot, Spring Data JPA, Spring Security, MariaDB, SSL witch Self Signet Certyficate, Docker </p>',
                '<p className="articleteaser--p">Starałem się stosować SOLID.</p>',
                '<h2 className="articleteaser--h2">Do przeklikania:</h2>',
                '<a className="articleteaser--a articleteaser--topspace" href="https://github.com/grzegorzmajor/mybackendapp" target="_blank" rel="noreferrer">Link do GitHuba.</a>'
                ],
            isMaximized: false },
        { id: 7,
            title: "Mini Games",
            content: [
                '<p className="articleteaser--p articleteaser--topspace">Niewielka minigra w typu lotto. Aplikacja napisana w języku Java. Wersja konsolowa lub w okienku. Napisana w ramach kursu Get Java Pro, który stworzył Bartłomiej Kalka.</p>',
                '<img className="articleteaser--img articleteaser--img--right" src="/img/lotek2.png" alt=""/>',
                '<p className="articleteaser--p">Środowisko: InteliJ IDEA Ultimate</p>',
                '<p className="articleteaser--p">Wykorzystałem: Maven, JUnit5, AssertJ, AWT i Swing, wzorzec Builder, Set, HashSet, SecureRandom, Scanner, </p>',
                '<p className="articleteaser--p">Starałem się stosować SOLID. Chociaż z perspektywy czasu widzę ile błędów zrobiłem po drodze, oraz jak wiele jeszcze nauki było przede mną. I z pewnością jest nadal.</p>',
                '<p className="articleteaser--p">Jednym z rażących błędów które dziś widzę jest to, że nazywając obiekty używałem słów, które dla doświadczonych programistów mogą mieć pewne konkretne znaczenie. Przykładem jest choćby klasa LotekConsoleBuilder, która z wzrocem Builder nie ma nic wspólnego - za to klasa GameConfigurator już owszem ma!</p>',
                '<p className="articleteaser--p">Było to dla mnie ogromne wyzwanie, gdyż podstaw języka Java uczyłem się bardzo dawno temu. Rozpocząłem pisać ten kod w ramach kursu bez wcześniejszego przypomnienia podstaw rzucając się trochę na głęboką wodę. Cenne doświadeczenie, które dało mi trochę "wiatru w skrzydła"</p>',
                '<p className="articleteaser--p">Moje pierwsze podejście do pisania testów jednostkowych, oraz pracy z Code Review. Gra polega na tym, że gracz wybiera określoną ilość liczb, nastepnie aplikacja losuje swoja listę, gracz dostaje wynik liczby swoich trafień.</p>',
                '<img className="articleteaser--img articleteaser--img--left" src="/img/lotek1.png" alt=""/>',
                '<p className="articleteaser--p">Aplikacja uruchomiona z parametrem --window uruchamia się w wersji z okienkami. Bez parametru - jej wersja konsolowa.</p>',
                '<h2 className="articleteaser--h2">Do przeklikania:</h2>',
                '<a className="articleteaser--a articleteaser--topspace" href="https://github.com/grzegorzmajor/minigamesGetJavaPro" target="_blank" rel="noreferrer">Link do GitHuba.</a>'
                ],
            isMaximized: false },
        { id: 8,
            title: "Job Offers - Backend",
            content: [
                '<img className="articleteaser--img articleteaser--img--right" src="/img/offerbackend1.png" alt=""/>',
                '<p className="articleteaser--p articleteaser--topspace">Aplikacja z kursu Junior Java Ready również Bartłomieja Kalki.</p>',
                '<p className="articleteaser--p articleteaser--topspace">Na wstępie zaznaczam, że jest to apka do portfolio, stąd udostepnione publicznie są chociażby Mongo Express, czy Redis Commander.</p>',
                '<p className="articleteaser--p">Pierwsze poważniejsze podejście do Springa, testów integracyjnych, jak i tworzenia aplikacji typu backend.</p>',
                '<p className="articleteaser--p">Środowisko: InteliJ IDEA Ultimate</p>',
                '<p className="articleteaser--p">Wykorzystałem: Spring, Spring Boot, MongoDB, Mongo Express, Redis, Redis Commander, Maven, JUnit5, AssertJ, Hamcrest</p>',
                '<p className="articleteaser--p">Nie jest to kod jeden do jednego tak jak w kursie, bo dodawałem coś od siebie gdy mi to przyszło do głowy.</p>',
                '<p className="articleteaser--p">Moje ważniejsze własne zmiany to nowsze wersje Javy oraz Springa, co wymusiło na mnie samodzielne rozwiązania kilka problemów z tym związanych.</p>',
                '<p className="articleteaser--p">Dodatkową istotną zmianą to serwer na którym aplikację postawiłem. Zmiana z polecanoego w kursie aws na serwer vps z oferty ovh - postawiony na Debianie. Korzyść jaka płynie dla mnie z tej zmiany to, że nauczyłem się wrzucać obrazy dockerowe ręcznie z pomocą pliku tar. Kilku przydatnych komend linuxowych.</p>',
                '<p className="articleteaser--p">Przy okazji jednym z problemów jaki musiałem rozwiązać to problem z łączeniem się springowej apki z redisem.</p>',
                '<h2 className="articleteaser--h2">Do przeklikania:</h2>',
                '<div className="articleteaser--flex"><a className="articleteaser--a articleteaser--topspace" href="https://github.com/grzegorzmajor/JobOffers" target="_blank" rel="noreferrer">Link do GitHuba.</a><a className="articleteaser--a articleteaser--topspace" href="https://api.major.ovh:18000/swagger-ui/index.html" target="_blank" rel="noreferrer">Swagger.</a><a className="articleteaser--a articleteaser--topspace" href="https://api.major.ovh:18082/" target="_blank" rel="noreferrer">Redis Commander.</a><a className="articleteaser--a articleteaser--topspace" href="https://api.major.ovh:18081/" target="_blank" rel="noreferrer">Mongo Express.</a></div>'
            ],
            isMaximized: false },
        // { id: 2,
        //     tittle: "Job Offers - Frontend",
        //     content: [
        //         '<p className="articleteaser--p articleteaser--topspace">Frontend będzie!</p>',
        //         '<p className="articleteaser--p">Ale nie skorzystam z gotowca z kursu, tylko zrobię go sam!</p>'
        //     ],
        //     isMaximized: false },
        { id: 9,
            title: "Ta strona",
            content: [
                '<p className="articleteaser--p">Początkowo:</p>',
                '<img className="articleteaser--img articleteaser--img--right" src="/img/strona1.png" alt=""/>',
                '<p className="articleteaser--p articleteaser--topspace">Moja strona web, która ma być obecnie głównie stroną cv/portfolio. Miejscem do którego będę się odnosił w CV, które będzie też przewodnikiem po portfolio gdzie będzie można przeklikać się na githuba moich projektów czy wystawionych na moim serwerze aplikacji.</p>',
                '<p className="articleteaser--p articleteaser--topspace">To zaledwie początek pracy nad nią, ale udostępniam na tym etapie by już dziś mieć gdzie pokazać swoje portfolio.</p>',
                '<p className="articleteaser--p">Jest też kilka dodatkowych opcji, które chciałbym zaimplementować, ale na to przyjdzie czas.</p>',
                '<p className="articleteaser--p">Technologie: Node, React, React Router, html-react-parser, Sass(scss), grafika SVG, HTML5, FlexBox, strona responsywna.</p>',
                '<p className="articleteaser--p"></p>',
                '<p className="articleteaser--p">Aktualizacja 24.09.2023:</p>',
                '<p className="articleteaser--p">Obecnie strona ma budowę modułową, schemat w jaki sposób jest przemyślana przedstawia jedno ze zdjęć. Na głównej stronie poszczególne moduły strony wrzucane są w iFrame. Zostało to rozwiązane w taki sposób, by rozwiązać problem z CORS. Dodatkowa korzyść to łatwiejsza implementacja nowości czy zmiany w poszczególnych modułach. Pojedynczy moduł jest czytelniejszy niż wielki kolos z ogromną ilością komponentów.</p>',
                '<img className="articleteaser--img articleteaser--img--left" src="/img/web.png" alt=""/>',
            ],
            isMaximized: false },
        { id: 10,
            title: "Zanim na poważnie w oko wpadła mi Java",
            content: [
                '<p className="articleteaser--p articleteaser--topspace">Moja wcześniejsza historia to epizody, które miały ogromny wpływ na to, że obecnie jest mi troszkę łatwiej rozumieć co robię dziś.</p>',
                '<p className="articleteaser--p">Pierwsza fascynacja programowaniem to LOGO w podstawówce patrząc jak kolega obok wymiata z grafiką zółwia. Później pojawił się Pascal na studiach których niestety nie skończyłem z powodów kilku.</p>',
                '<p className="articleteaser--p">Tam też zaczęło się moje pierwsze podejście do C++, Obiektów i później języka Java.</p>',
                '<p className="articleteaser--p">W między czasie zrobiłem kilka stron, z których do obecnych czasów przetrwała tylko jedna. Pierwsza to HTML4 i baaardzo stare czasy - istniał jeszcze Netscape Navigator 4. Pozostałe to już HTML5, CSS3, PHP, MySQL i coś tam w JavaScript - ale wtedy JS tylko w formie kopiuj-wklej.</p>',
                '<p className="articleteaser--p">Na dwóch stronkach nawet udało mi się coś zarobić.</p>',
                '<p className="articleteaser--p">Później nauka wszystkiego na co trafiłem, głównie frontendu. Platformy StudiujęIT i Udemy. Zapragnąłem zmian w mojej karierze zawodowej.</p>',
                '<p className="articleteaser--p">Wtedy trafiłem na podcast Bartka Kalki na Spotify, sprawił wrażenie gościa, który wie o czym mówi! W niedługim czasie później rozpocząłem pierszy kurs związany z Javą właśnie od Bartka. Moja opinia na jego temat się potwierdziła, bo po pierwsze dobrze mi się pracuje z jego kursami. Po drugie to własnie wtedy polubiłem Javę na dobre i doszedłem do wniosku, że to właśnie jest moja droga do IT.<br><br></p>',
                '<h2 className="articleteaser--h2">Kilka rzeczy, którymi bawiłem się wcześniej:</h2>',
                '<p className="articleteaser--p">Strona dla stajni koni wyścigowych moich znajomych. Na podstawie ich wytycznych. PHP, HTML5, CSS3 - nic specjalnie wymagającego, ale pewnie w przyszłośći coś zmienię, tym bardziej że chciałbym dodać możliwość prostej edycji dla nich.</p>',
                '<a className="articleteaser--a articleteaser--topspace" href="http://stajniaskorogoszcz.pl/" target="_blank" rel="noreferrer">Stajnia Koni wyścigowych.</a>',
                '<p className="articleteaser--p"><br>Kilka projektów z kursów na platformie StudiujęIt z wykorzystaniem JS tworzonych w czasie gdy jeszcze szukałem swojej drogi. Było ich więcej, ale te najbardziej mnie przyciągnęły i sprawiły, że zrobiłem coś więcej niż wymagane w kursie zadanie.</p>',
                '<div className="articleteaser--flex"><a className="articleteaser--a articleteaser--topspace" href="https://www.major.ovh/zegar/" target="_blank" rel="noreferrer">Zegar.</a><a className="articleteaser--a articleteaser--topspace" href="https://www.major.ovh/papierkamiennozyce/" target="_blank" rel="noreferrer">Papier, kamień, nożyce.</a><a className="articleteaser--a articleteaser--topspace" href="https://www.major.ovh/jednoreki/" target="_blank" rel="noreferrer">Jednortęki Bandyta!</a></div>',
            ],
            isMaximized: false },

        ]);
        const portfolioSectionRef = useRef(null);

        const toggleMaximized = (articleId) => {
            setArticlesList(prevArticlesList => {
                return prevArticlesList.map(article => {
                    if (article.id === articleId) {
                        return { ...article, isMaximized: !article.isMaximized };
                    } else {
                        return { ...article, isMaximized: false };
                    }
                });
            });

        };

        // useEffect(() => {
        //     portfolioSectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start',  inline: 'start' });
        //     window.scrollY = 0;
        // }, [articlesList]);

        return(

            <main className="main portfolio">

                <section ref={portfolioSectionRef} className="portfolio-section"></section>

                {articlesList.map( (article) => (
                    <ArticleTeaser
                        key={article.id}
                        title={article.title}
                        content={article.content}
                        maximize={article.isMaximized}
                        toggleMaximized={() => toggleMaximized(article.id)}
                    />))}
                </main>
        )

}

export default Portfolio