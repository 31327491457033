import {Link} from 'react-router-dom';
import './kontakt.scss';


export default function Kontakt() {
    return (
        <main className="main kontakt">
            <h1 className="kontakt__h1"> Zapraszam do kontaktu!</h1>
            <h2 className="kontakt__h2"> E-Mail: <Link to="mailto:grzegorz@major.ovh">grzegorz@major.ovh</Link></h2>
            <h3 className="kontakt__h3"> Telefon: <Link to="tel:+48601869886">601869886</Link> </h3>
        </main>
    );
}

