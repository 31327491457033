import React from 'react';
import parse from 'html-react-parser';
import './articleteaser.scss';

const ArticleTeaser = ({ title, content, maximize, toggleMaximized }) => {
    const contentHTML = content.map(item => parse(item));

    return (
        <div className={`articleteaser ${maximize ? "flex--maximizer" : ""}`}>
            <h2 onClick={toggleMaximized} className="articleteaser--h2">{title}
            <span  className="material-symbols-outlined">
                {maximize ? "unfold_less" : "unfold_more"}
            </span></h2>
            {contentHTML}
        </div>
    );
};

export default ArticleTeaser;
