import React from 'react';

import './external.scss';

const ExternalPageWithTimestamp = ({ externalUrl, title }) => {
  const timestamp = new Date().getTime();
  const urlWithTimestamp = `${externalUrl}?timestamp=${timestamp}`;

  return (
    <main className="main">
      <iframe className="frame" src={urlWithTimestamp} title={title || 'External Page'}></iframe>
    </main>
  );
};

export default ExternalPageWithTimestamp;
