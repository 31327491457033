import Button from './button';
import {Link} from 'react-router-dom';
import './menu.scss'

const BasicMenu = (props) => {

    return (
        <div className="menus menus--basic">
            <div className="menus__buttonClose menus__buttonClose--menu menus__buttonClose--menu--basic"  onClick={ props.disableVisibiliti } >
                <span className="material-symbols-outlined">close</span>
            </div>
            <Link to="/"><Button name={"home"} disableMenu={props.disableVisibiliti} /></Link>
            <Link to="/portfolio"><Button name={"portfolio"} disableMenu={props.disableVisibiliti} /></Link>
            <Link to="/blog"><Button name={"blog"} disableMenu={props.disableVisibiliti} /></Link>
            <Link to="/kontakt"><Button name={"kontakt"} disableMenu={props.disableVisibiliti} /></Link>
        </div>
    )
}

export default BasicMenu;