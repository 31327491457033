import { Component, Fragment } from 'react';
import { Routes, Route } from "react-router-dom";

import Layout from './components/layout/Layout';
import StartPageComponent from './components/start/startPage';
import Portfolio from './components/portfolio/portfolio';
import Kontakt from './components/kontakt/Kontakt';
import NoMatch from './components/404/NoMatch';
import ExternalPageWithTimestamp from './components/external/ExternalPageWithTimestamp';

import './app.scss';

class App extends Component {
  render() {
    return (
      <Fragment>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={ <StartPageComponent /> } />
            <Route path="portfolio" element={<Portfolio />} />
            <Route path="kontakt" element={<Kontakt />} />
            <Route path="blog" element={<ExternalPageWithTimestamp externalUrl="https://api.major.ovh:8666" />} />
            <Route path="admin" element={<ExternalPageWithTimestamp externalUrl="https://api.major.ovh:8667" />} />


            <Route path="*" element={<NoMatch />} />
          </Route>
        </Routes>
      </Fragment>
    );
  }
}

export default App
