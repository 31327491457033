const Button = ({name,disableMenu}) => {
    return (
        <div className="menus__buttonMenu" onClick={disableMenu}  >
            <svg className="menus__buttonMenu__svg" height="60" width="270">
            <path d="M 20 14 L38 28 L20 42 "
                className="menus__buttonMenu__svg__path menus__buttonMenu__svg__path--arrow  menus__buttonMenu__svg__path--hover" />

            <path d="M 2 44 L2 14 q 0 -12 12 -12 "
                className="menus__buttonMenu__svg__path menus__buttonMenu__svg__path--hover" />
            <path d="M 12 2 L42 2 q 12 0 12 12 "
                className="menus__buttonMenu__svg__path menus__buttonMenu__svg__path--hover" />
            <path d="M 42 54 L 14 54 q -12 0 -12 -12 "
                className="menus__buttonMenu__svg__path menus__buttonMenu__svg__path--hover" />
            <path d="M 54 14 L 54 44 q 0 12 12 12"
                className="menus__buttonMenu__svg__path menus__buttonMenu__svg__path--hover" />
            <circle cx="42" cy="54" r="2" className="menus__buttonMenu__svg__path menus__buttonMenu__svg__path--hover" />
            <circle cx="254" cy="50" r="2" className="menus__buttonMenu__svg__path menus__buttonMenu__svg__path--hover" />
            <path d="M 66 56 L 250 56 q 4 0 4 -4"
                className="menus__buttonMenu__svg__path menus__buttonMenu__svg__path--hover" />
            Sorry, your browser does not support inline SVG.
            </svg>
            <p className="menus__buttonMenu__p">{name}</p>
        </div>
    )
}

export default Button