import { Component, Fragment } from 'react';
import { Outlet } from "react-router-dom";

import Header from '../header/Header';
import BasicMenu from '../menu/basic_menu';

class Layout extends Component {
    state = {
        basic_menu_visibility: false
    }

    render() {
        return(
            <Fragment>
                <Header setBasicMenuVisibiliti={this.enableBasicMenuVisibility} />
                <Outlet />
                { this.state.basic_menu_visibility && <BasicMenu disableVisibiliti={this.disabeBasicMenuVisibility} /> }
            </Fragment>
        )
    }


  disabeBasicMenuVisibility = () => {
    this.setState({basic_menu_visibility: false})
  }

  enableBasicMenuVisibility = () => {
    this.setState({basic_menu_visibility: true})
  }

}

export default Layout