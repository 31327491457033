import './notfound.scss';

export default function NoMatch() {
    return (
        <main className="main notFound">
            <h1 className="notFound__h1">Oops!</h1>
            <h2 className="notFound__h2">Coś się zepsuło!</h2>
            <h3 className="notFound__h3">404 - page not found</h3>
        </main>
    );
}

