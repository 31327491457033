import {Component} from 'react';
import {Link} from 'react-router-dom';

import './main.scss';

class StartPage extends Component {
    render() {
        return (
                <main className="main">
                    <div className="main__sentencje">
                        <h1 className="main__sentencje__lines main__sentencje__lines--line1">Grzegorz Major</h1>
                        <h2 className="main__sentencje__lines main__sentencje__lines--line2">Junior Java Developer z pasją</h2>
                        <h2 className="main__sentencje__lines main__sentencje__lines--line3">Twórca bloga "Programista w budowie"</h2>

                        <p className="main__sentencje__text">Cześć! Jestem pasjonatem programowania. Uwielbiam rzucanie sobie wyzwań i poznawanie nowych technologii. To, co najbardziej mnie motywuje, to te małe sukcesy - efekty mojej pracy, które widzę. To jak zbieranie znaczków, każdy mały sukces jest jak znalezienie białego kruka.</p>
                        <h3 className="main__sentencje__text main__sentencje__text--no__bottom__space  main__sentencje__text--no__top__space main__sentencje__text--green">Główny stack technologiczny:</h3>
                        <p className="main__sentencje__text main__sentencje__text--no__bottom__space  main__sentencje__text--no__top__space main__sentencje__text--indentation main__sentencje__text--green">
                            Java, Spring Boot, Spring Framework, Maven, JWT, Lombok, PostgreSQL, MariaDB, Redis, Flyway, Testcontainers, JUnit5, AssertJ, Scheduler, Swagger, Validation, RestAssured, Docker + DockerHub, Web Client, Clean Code, Modular Monolith </p>
                        <p className="main__sentencje__text main__sentencje__text--no__bottom__space  main__sentencje__text--no__top__space main__sentencje__text--indentation main__sentencje__text--indentation main__sentencje__text--green">
                            IntelliJ IDEA i Visual Studio Code</p>
                        <p className="main__sentencje__text main__sentencje__text--no__top__space main__sentencje__text--indentation main__sentencje__text--indentation main__sentencje__text--green">
                            Git, GitHub</p>
                        <h3 className="main__sentencje__text main__sentencje__text--no__bottom__space  main__sentencje__text--no__top__space">Ponadto stosowałem lub uczyłem się i coś potrafię:</h3>
                        <p className="main__sentencje__text main__sentencje__text--no__bottom__space  main__sentencje__text--no__top__space main__sentencje__text--indentation">
                            SOLID, Wzorce projektowe, TDD</p>
                        <p className="main__sentencje__text main__sentencje__text--no__bottom__space  main__sentencje__text--no__top__space main__sentencje__text--indentation">
                            HTML5, CSS3, SASS / SCSS, JavaScript, podstawy React, React Router, Redux, Axios, PHP, C++</p>

                        <p className="main__sentencje__text main__sentencje__text--no__bottom__space  main__sentencje__text--no__top__space main__sentencje__text--indentation">
                            Scrum, Agile</p>
                        <p className="main__sentencje__text">Obecnie od kilku miesięcy pracuję jako Java Daveloper, początkowo na stażu. Jednak już od samego początku samodzielnie projektowałem częsci sytemu nad którym pracuję. Jednak projekt ten jest dla mnie raczej dodatkowym zajęciem. Jestem gotowy do pracy i zdobywania nowych umiejętności oraz do współpracy z zespołem. Moją siłą jest zdeterminowanie i pasja do programowania, a także zdolność do kreatywnego myślenia i rozwiązywania problemów. Obecnie poszukuję zatrudnienia jako Java Developer, by zmienić również moje główne zajęcie. Zapraszam do zapoznania się z moim portfolio. Jeśli jesteś osobą rekrutującą, gotową sprawdzić co mam do zaoferowania zapraszam do kontaktu - rozmowa, projekt rekrutacyjny do wykonania - bardzo chętnie.</p>

                        <p className="main__sentencje__text main__sentencje__text--center main__sentencje__text--bigger">
                            <Link to="https://github.com/grzegorzmajor" rel="nofollow noreferrer" target="_blank" className="link" >GitHub</Link>
                            <Link to="https://www.linkedin.com/in/grzegorz-major" rel="nofollow noreferrer" target="_blank" className="link" >LinkedIn</Link>
                            <Link to="https://drive.google.com/drive/folders/18JVYNU9A6uweTsaRM1c65VFi12zgQem_" rel="nofollow noreferrer" target="_blank" className="link" >Certyfikaty</Link></p>

                    </div>
                    <div className="main__foto">
                        <img className="main__foto__img" src="/img/foto4.webp" alt="n"/>
                    </div>
                </main>
        )
    }
}

export default StartPage