import './header.scss';
import {Link} from 'react-router-dom';

const Header = ({setBasicMenuVisibiliti}) => {
    return (
        <header className="header">
            <div className="header__lineBeforeLogo"></div>
            <div className="header__logo">
            {svh_logo_image()}
            </div>
            <div className="header__lineAfterLogo"></div>
            <div className="header__button header__button--menu" onClick={ setBasicMenuVisibiliti }><span className="material-symbols-outlined" >menu_open</span></div>
            <Link to="/admin"><div className="header__button header__button--admin"><span className="material-symbols-outlined">settings</span></div></Link>
        </header>
    )
}

function svh_logo_image() { return (
    <svg className="header__logo__svg" width="325" height="200">
        <rect x="15" y="50" width="25" height="110" fill="currentcolor" />
        <rect x="80" y="50" width="25" height="110" fill="currentcolor" />
        <polygon points="40,50 40,90 60,130 60,90" fill="currentcolor" />
        <polygon points="60,130 60,90 80,50 80,90 " fill="currentcolor" />

        <polygon points="110,80 110,50 170,130 170,160 " fill="currentcolor" />
        <polygon points="110,100 110,120 140,120 130,100 " fill="currentcolor" />

        <polygon points="200,160 175,160 175,90 200,90 " fill="currentcolor" />
        <polygon points="165,170 165,200 200,160 175,160 " fill="currentcolor" />
        <polygon points="173,65 188,80 203,65 188,50" fill="currentcolor" />

        <polygon points="250,185 280,185 205,110 205,140 " fill="currentcolor" />
        <polygon points=" 250,185 280,185 325,140 325,110" fill="currentcolor" />
        <polygon points="280,65 250,65 325,140 325,110" fill="currentcolor" />
        <polygon points="205,140 205,110 250,65 280,65 " fill="currentcolor" />

        <polygon points="255,145 255,105 260,105 260,145" fill="currentcolor" />
        <polygon points="255,105 260,105 275,120 275,125" fill="currentcolor" />
        <polygon points="255,125 260,125 275,145 270,145" fill="currentcolor" />
        <polygon points="275,120 275,125 255,135 255,130 " fill="currentcolor" />

        Sorry, your browser does not support inline SVG.
    </svg>
)}

export default Header